import React from "react"

const FormBody = ({ children }: { children: JSX.Element }) => {
    return (
        <div className="card-body py-5 px-4"> {children}</div>
    )
}


export default FormBody
