import * as React from "react"
import { graphql } from "gatsby"
import { useRef } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PaidTourFormSection from "../../components/paid/header-section"
import PageSection from "../../components/page-section"
import FormContainer from "../../components/form/form-container"
import FormBody from "../../components/form/form-body"
import FormFooter from "../../components/form/form-footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileSignature } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"
import Swal from 'sweetalert2/dist/sweetalert2.js'

const PaidToursFormPage = () => {
    const formElement = useRef<HTMLFormElement>();
    const date1Element = useRef<HTMLInputElement>();

    const ApiEndpoint = "https://wodnawarszawa.pl/api/";

    const Api = axios.create();
    Api.defaults.headers.post['Content-Type'] = 'application/json';

    const submitForm = async () => {

        const form = formElement?.current;

        if (form.checkValidity()) {

            form.classList.remove('was-validated');

            // retrieve data from form inputs
            const formInputs = form.getElementsByTagName('input');
            const formSelects = form.getElementsByTagName('select');
            const formTextareas = form.getElementsByTagName('textarea');
            let formDataset = {};
            for (let i = 0; i < formInputs.length; i++) {
                formDataset[formInputs[i].id] = formInputs[i].value;
            }
            for (let i = 0; i < formSelects.length; i++) {
                formDataset[formSelects[i].id] = formSelects[i].value;
            }
            for (let i = 0; i < formTextareas.length; i++) {
                formDataset[formTextareas[i].id] = formTextareas[i].value;
            }

            const payload = {
                variant: "addPaidTourInquiry",
                fields: {
                    tourDate: formDataset['tourDate'],
                    tourTime: formDataset['tourTime'],
                    tourShipType: formDataset['tourShipType'],
                    ticketsAmount: formDataset['ticketsAmount'],
                    fullName: formDataset['fullName'],
                    mail: formDataset['mail'],
                    phone: formDataset['phone'],
                    notes: formDataset['notes']
                }
            }

            Swal.fire({
                icon: 'info',
                title: 'Rejestruję Twoje zapytanie...',
                text: 'Wysyłamy Twój formularz. Prosimy czekać...',
                allowOutsideClick: false,
                showConfirmButton: false,
            });

            // send form:

            const response = await axios.post(ApiEndpoint, payload);
            const jsonResponse = response.data;

            if (response.status == 200 && jsonResponse?.success) {

                Swal.fire({
                    icon: 'success',
                    title: 'Pomyślnie zarejestrowano',
                    text: 'Dziękujemy za zapytanie. Prosimy oczekiwać na kontakt od osoby z naszego zespołu.',
                    allowOutsideClick: true,
                })

                form.reset();

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Coś poszło nie tak...',
                    text: 'Wystąpił błąd podczas wysłania formularza. Prosimy sprobować ponownie lub zgłosić błąd na kontakt@wodnawarszawa.pl',
                    allowOutsideClick: true,
                });
            }


        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Błędy formularza',
                text: 'Formularz zawiera błędy lub braki. Sprawdź i wyślij ponownie',
                allowOutsideClick: true,
            });
            form.classList.add('was-validated');
        }

    }

    return (
        <Layout>
            <Seo title="Rejsy płatne - Zapytanie" />
            <PaidTourFormSection />
            <PageSection className="bg-white">
                <FormContainer title={"Formularz zapytania"}>
                    <FormBody>
                        <form ref={formElement} className="row g-3 needs-validation" noValidate>
                            <label htmlFor="tourDate" className="col-form-label col-sm-4 text-muted">Preferowany termin i godzina rejsu</label>
                            <div className="col-sm-8">
                                <div className="input-group">
                                    <input ref={date1Element} value={null} type="date" className="form-control" id="tourDate" placeholder="Dzień" required />
                                    <select id="tourTime" className="form-select form-control" aria-label="Wybierz porę" aria-describedby="tourTimeHelp" required>
                                        <option value="" selected>Wybierz godzinę</option>
                                        <option value="9">9:00</option>
                                        <option value="10">10:00</option>
                                        <option value="11">11:00</option>
                                        <option value="12">12:00</option>
                                        <option value="13">13:00</option>
                                        <option value="14">14:00</option>
                                        <option value="15">15:00</option>
                                        <option value="16">16:00</option>
                                        <option value="17">17:00</option>
                                        <option value="18">18:00</option>
                                        <option value="19">19:00</option>
                                    </select>
                                </div>

                            </div>

                            <label htmlFor="ticketsAmount" className="col-form-label col-sm-4 text-muted">Liczba uczestników</label>
                            <div className="col-sm-8">
                                <input onChange={null} type="number" className="form-control" id="ticketsAmount" placeholder="" aria-describedby="ticketsAmountHelp" min={1} required />
                                <div className="invalid-feedback">
                                    Proszę uzupełnić wymagane pole.
                                </div>
                            </div>

                            <label htmlFor="tourShipType" className="col-form-label col-sm-4 text-muted">Rodzaj statku</label>
                            <div className="col-sm-8">
                                <div className="input-group">
                                    <select id="tourShipType" className="form-select form-control" aria-label="Wybierz rodzaj statku" aria-describedby="tourShipTypeHelp" required>
                                        <option value="" selected>Wybierz rodzaj statku</option>
                                        <option value="Statek">Statek rejsowy</option>
                                        <option value="Motorówka">Motorówka</option>
                                        <option value="Łódź drewniana">Łódź drewniana</option>
                                        <option value="Łódź elektryczna">Łódź elektryczna</option>
                                        <option value="Żaglówka">Żaglówka</option>
                                    </select>
                                    <div id="tourShipTypeHelp" className="form-text">Wybierz rodzaj statku, my dobierzemy i zaproponujemy Tobie konkretną jednostkę.</div>
                                </div>
                            </div>

                            <hr className="mt-5 mb-4"></hr>

                            <label htmlFor="fullName" className="col-form-label col-sm-4 text-muted ">Imię i Nazwisko</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="fullName" placeholder="" aria-describedby="fullNameHelp" required />
                                <div className="invalid-feedback">
                                    Proszę uzupełnić wymagane pole.
                                </div>
                                <div id="fullNameHelp" className="form-text">Dane organizatora</div>
                            </div>

                            <label htmlFor="mail" className="col-form-label col-sm-4 text-muted ">Email</label>
                            <div className="col-sm-8">
                                <input type="email" className="form-control" id="mail" placeholder="" aria-describedby="mailHelp" required />
                                <div className="invalid-feedback">
                                    Proszę uzupełnić wymagane pole.
                                </div>
                                <div id="mailHelp" className="form-text">Na ten adres wyślemy ofertę i szczegóły rejsu</div>
                            </div>

                            <label htmlFor="phone" className="col-form-label col-sm-4 text-muted">Telefon</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="phone" placeholder="" aria-describedby="phoneHelp" required />
                                <div className="invalid-feedback">
                                    Proszę uzupełnić wymagane pole.
                                </div>
                                <div id="phoneHelp" className="form-text">Numer na który oddzwonimy w razie konieczności</div>
                            </div>

                            <label htmlFor="phone" className="col-form-label col-sm-4 text-muted">Twoje uwagi</label>
                            <div className="col-sm-8">
                                <textarea className="form-control" id="notes" rows={3} placeholder="Jeśli masz jakiekolwiek nietypowe wymagania np. zmiany miejsca startu, zakończenia lub czasu trwania rejsu, wpisz je tutaj."></textarea>
                            </div>

                            <hr className="my-4"></hr>

                            <label htmlFor="legal" className="col-form-label col-sm-4 text-muted">Zgody</label>
                            <div className="col-sm-8">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="check2" required />
                                    <label className="form-check-label" htmlFor="check2">
                                        Akceptuję <a target="_blank" href="/polityka-prywatnosci">politykę prywatności</a> i zgadzam się na przetwarzanie moich danych osobowych
                                    </label>
                                </div>
                            </div>

                        </form>
                    </FormBody>
                    <FormFooter>
                        <div className="container-fluid p-0">
                            <div className="row align-items-center">
                                <button onClick={submitForm} type="button" className="btn btn-secondary btn-lg"><FontAwesomeIcon icon={faFileSignature} className="pe-2" />Wyślij zapytanie</button>
                            </div>
                        </div>
                    </FormFooter>
                </FormContainer>
            </PageSection>
        </Layout >
    )
}

export default PaidToursFormPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;