import { faClockFour, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faAnchor, faDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react"
import PageSection from "../page-section"

const PaidTourFormSection = () => {
    return (
        <PageSection>
            <h1 className="pb-3">Zapytanie o rejs płatny</h1>
            <p>
                Prosimy o wypełnienie formularza zapytania.
            </p>
            <p>
                Po wysłaniu formularza, skontaktujemy się z Tobą w ciągu 24 godzin, aby poinformować o cenie, potwierdzić termin, ustalić warunki i sfinalizować rezerwację.
            </p>
            <h4 className="py-3"><FontAwesomeIcon icon={faClockFour} className={"pe-2"}></FontAwesomeIcon>Czas trwania rejsu</h4>
            <p>
                Każdy z rejsów trwa <strong>1 godzinę</strong>.
            </p>
            <h4 className="py-3"><FontAwesomeIcon icon={faAnchor} className={"pe-2"}></FontAwesomeIcon>Miejsce startu i zakończenia</h4>
            <p>
                Każdy z rejsów rozpoczyna się i kończy w <a href="https://goo.gl/maps/cK42Yf9nNXJejXHVA" target="_blank"><strong>Dworcu Wodnym</strong></a>.
            </p>
            <h4 className="py-3"><FontAwesomeIcon icon={faDollar} className={"pe-2"}></FontAwesomeIcon>Cena rejsu</h4>
            <p>
                Ceny już <strong>od 65 zł od osoby</strong>
            </p>
        </PageSection>
    )
};

export default PaidTourFormSection