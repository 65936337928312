import { faFileLines } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const FormContainer = ({ children, title }: { children: React.ReactNode, title: string }) => {
    return (
        <div className="card bg-primary mb-3" >
            <div className="card-header p-4"><h5 className="card-title border-light pt-2"><FontAwesomeIcon icon={faFileLines} className="pe-2" />{title}</h5></div>
            {children}
        </div >
    )
}

export default FormContainer